<template>
  <div id="app">
    <button @click="getLocation" class="getLocation">获取经纬度</button>
    <div v-if="location.latitude && location.longitude">
      <p>纬度: {{ location.latitude }}</p>
      <p>经度: {{ location.longitude }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      location: {
        latitude: '',
        longitude: ''
      },
      formData: {
        longitude: '',
        latitude: ''
      }
    }
  },
  methods: {
    getLocation(event) {
      event.preventDefault();
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(this.sendPosition, this.showError);
      } else {
        alert("此浏览器不支持地理定位");
      }
    },
    sendPosition(position) {
      this.location = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude
      };
      this.formData.longitude = this.location.longitude;
      this.formData.latitude = this.location.latitude;
      console.log(`Latitude: ${this.location.latitude}, Longitude: ${this.location.longitude}`);
    },
    showError(error) {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          alert("User denied the request for Geolocation.");
          break;
        case error.POSITION_UNAVAILABLE:
          alert("Location information is unavailable.");
          break;
        case error.TIMEOUT:
          alert("The request to get user location timed out.");
          break;
        case error.UNKNOWN_ERROR:
          alert("An unknown error occurred.");
          break;
      }
    },
  }
}
</script>

<style>
</style>
